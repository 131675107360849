import React from 'react';

import * as Styles from '../Styles.css';

export default function Circle({size, width=size, height=size, className, fill, shapeColor, strokeColor, circleFill = 'currentColor', circleStroke = 'currentColor', circleRadius='16', ...props}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' className={`${Styles.svg} ${className}`} width={width} height={height} fill={fill} {...props}>
      <path d="M0.666504 7.99984C0.666504 9.94476 1.43912 11.81 2.81439 13.1853C4.18965 14.5606 6.05492 15.3332 7.99984 15.3332C9.94476 15.3332 11.81 14.5606 13.1853 13.1853C14.5606 11.81 15.3332 9.94476 15.3332 7.99984C15.3332 6.05492 14.5606 4.18965 13.1853 2.81439C11.81 1.43912 9.94476 0.666504 7.99984 0.666504C6.05492 0.666504 4.18965 1.43912 2.81439 2.81439C1.43912 4.18965 0.666504 6.05492 0.666504 7.99984V7.99984Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
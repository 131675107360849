import listDux from './lib/listDux';

const {reducer, actions, epics} = listDux({
  name: 'googlePlaces',
  url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
  processResponse: ([{response={status: responseStatus, predictions: items=[]}, status}]=[]) => {
    if (status==200 && responseStatus=='OK')
      return {items, count: items.length};
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

export {actions};
export {epics};
export default reducer;
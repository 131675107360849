import { combineReducers } from 'redux';

import listDux from './lib/listDux';

const { reducer: $countries, actions: countries, epics: countriesEpics } = listDux({
  name: 'lookup.countries',
  url: '/api/system/lookup/countries',
  processResponse: ([{ response: items } = {}, {0: {payload={}}={}}=[]]=[]) =>  {
    return ({
      ...(!items ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      ...(items ? { ...payload, items} : {}),
    })
  }
});

const { reducer: $postalCodePatterns, actions: postalCodePatterns, epics: postalCodePatternsEpics } = listDux({
  name: 'lookup.postalCodePatterns',
  url: '/api/system/lookup/postalCodePatterns',
  processResponse: ([{ response: items } = {}, {0: {payload={}}={}}=[]]=[]) =>  {
    return ({
      ...(!items ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      ...(items ? { ...payload, items} : {}),
    })
  }
});

const { reducer: $states, actions: states, epics: statesEpics } = listDux({
  name: 'lookup.states',
  url: '/api/system/lookup/states',
  processResponse: ([{ response: items } = {}, {0: {payload={}}={}}=[]]=[]) =>  {
    return ({
      ...(!items ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      ...(items ? { ...payload, items} : {}),
    })
  }
});

const { reducer: $currencies, actions: currencies, epics: currenciesEpics } = listDux({
  name: 'lookup.currencies',
  url: '/api/system/lookup/currencies',
  processResponse: ([{ response: items } = {}, {0: {payload={}}={}}=[]]=[]) =>  {
    return ({
      ...(!items ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      ...(items ? { ...payload, items} : {}),
    })
  }
});

export const actions = { countries, postalCodePatterns, states, currencies };
export const epics = [...countriesEpics, ...postalCodePatternsEpics, ...statesEpics, ...currenciesEpics];
export default combineReducers({ $countries, $postalCodePatterns, $states, $currencies });
import React from 'react';
import { createPortal } from 'react-dom';

import Surface from '../surface';
import Cell from '../cell';
import Row from '../row';
import Button from '../button';

import { container, snackbar, actionContainer } from './Snackbar.css';

export default function SnackbarComponent({ message, action, onClick, elevation = 6 }) {
  return (
    <Cell className={container}>
      <Surface {...{ elevation, className: snackbar }}>
        <Row alignItems='center'>
          <Cell>{message}</Cell>
          {
            action &&
            <Row className={actionContainer}>
              <Button buttonPattern='text' onClick={onClick}>{action}</Button>
            </Row>
          }
        </Row>
      </Surface>
    </Cell>
  )
} 
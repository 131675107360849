import React from 'react';
import { withMediaQueryProps } from '../media-query-2';
import { mediaQuery } from '../media-query';

function Cell({ children, flexGrow, flexShrink, alignSelf, mediaQuery={}, style={}, className='', ...rest }, ref) {
  style = {
    boxSizing: 'border-box',
    flexGrow,
    flexShrink,
    alignSelf,
    ...style,
  };
  
  return (
    <div {...{ ref, style, className, ...rest }}>{children}</div>
  );
}

export default withMediaQueryProps(mediaQuery(React.forwardRef(Cell)));

import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'quoteDocuments',
  url: '/api/enquiries/v2/quote-documents'
});

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'quoteDocuments',
  url: '/api/enquiries/v2/quote-documents',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'quoteDocuments',
  url: '/api/enquiries/v2/quote-documents',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'quoteDocuments',
  url: '/api/enquiries/v2/quote-documents',
});


const { reducer: $updateStatus, actions: updateStatus, epics: updateStatusEpics } = updateDux({
  name: 'quoteDocumentsUpdateStatus',
  url: '/api/enquiries/v2/quote-document/update-status',
});



export const actions = { list, create, details, update, updateStatus};
export const epics = [...listEpics, ...createEpics, ...detailsEpics, ...updateEpics, ...updateStatusEpics, ];
export default combineReducers({ $list, $create, $details,  $update,  $updateStatus});
import { combineReducers } from 'redux';

import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';

const { reducer: $create, actions: create, epics: createEpics } = createDux({
  name: 'linkFedexAccount',
  url: '/api/auth/v2/fedex-account-link-request',
});

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'linkFedexAccount',
  url: '/api/auth/v2/fedex-account-link-request',
});

const { reducer: $validateInvoice, actions: validateInvoice, epics: validateInvoiceEpics } = updateDux({
  name: 'validateInvoice',
  url: '/api/auth/v2/validate-invoice'
});

const { reducer: $validateOTP, actions: validateOTP, epics: validateOTPEpics } = updateDux({
  name: 'validateOTP',
  url: '/api/auth/v2/validate-otp'
});
  
export const actions = { create, details, validateInvoice, validateOTP };
export const epics = [...createEpics, ...detailsEpics, ...validateInvoiceEpics, ...validateOTPEpics];
export default combineReducers({ $create, $details, $validateInvoice, $validateOTP });
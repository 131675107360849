import React from 'react';

import Icon from './Icon';

export const documentation = [
  {
    type: 'Title', props: {display: 'Icon'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Icon Demo',
      preview: {
        type: Icon,
        props: {type: 'star', size: '40px',},
      },
      configurations: [
        {
          name: 'alignItems',
          label: 'Align Items',
          type: 'dropdown',
          options: ['', 'normal', 'stretch', 'center', 'start', 'end', 'flex-start', 'flex-end', 'baseline', 'first baseline', 'last baseline;', 'safe center', 'unsafe center', 'inherit', 'initial', 'unset',],
          isValueArrayPlainString: true,
          isOptionsArrayPlainString: true,
        },
        {
          name: 'justifyContent',
          label: 'Justify Content',
          type: 'dropdown',
          options: ['', 'center', 'start', 'end', 'flex-start', 'flex-end', 'left', 'right', 'normal', 'space-between', 'space-around', 'space-evenly', 'stretch', 'safe center', 'unsafe center', 'inherit', 'initial', 'unset',],
          isValueArrayPlainString: true,
          isOptionsArrayPlainString: true,
        },
        {
          name: 'type',
          label: 'Type',
          type: 'text',
          value: 'star',
        },
        {
          name: 'size',
          label: 'Size',
          type: 'number',
          value: '40px'
        },
        {
          name: 'width',
          label: 'Width',
          type: 'text',
        },
        {
          name: 'height',
          label: 'Height',
          type: 'text',
        },
      ],
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      params: [
        {name: 'children', description: 'Children of the DOM element \nAll React Components and DOM elements are valid',},
        {name: 'className', description: 'Class name to apply to the icon container',},
        {name: 'ref', description: 'Reference to the underlying DOM element (icon container)',},
        {name: 'alignItems', description: `Flex 'align-items' property to align icon along the main axis of the container`,},
        {name: 'justifyContent', description: `Flex 'justify-content' property to align icon along the secondary axis of the container`,},
        {name: 'type', description: `Optional. Icon type to be displayed. Currently, only supports material icon libaray \nTODO: Implement support for other icon libraries`,},
        {name: 'children', description: `Optional. To be used when not using the icon libraries \nAny vaild React elements are accepted. Images and icons recommended \nIMPORTANT: It's recommended not set both 'children' and 'type' at the same time`,},
        {name: 'size', description: `Set the 'font-size' property on the underlying icon element \nHas effect only when 'type' is set`,},
        {name: 'width', description: 'Width of the icon container',},
        {name: 'height', description: 'Height of the icon container',},
        {name: '-------', description: 'All the remaining parameters will be passed to underlying DOM element (icon container) as is',},
        {name: '', description: '',},
      ],
    }
  },
];
import React from 'react';

import SnackbarComponent from './SnackbarComponent';
import { SubjectRxjs } from './Subject';

export default class Snackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.subscription = SubjectRxjs.subscribe(snackbar => {
      this.setState({
        snackbar,
      })
    });
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
  render() {
    const { snackbar } = this.state;
    if (!snackbar)
      return false;
    return <SnackbarComponent {...snackbar} />
  }
}
import SnackbarStyles from '!!raw-loader!./Snackbar.css';

import {setSnackbar} from './Subject';

import Button from '../button';

export const documentation = [
  {
    type: 'Title', props: {display: 'Snackbar'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Button Demo',
      preview: {
        type: Button,
        props: {
          onClick: () => setSnackbar({
            message: 'This message will disappear in 5 seconds',
            action: 'OK',
            onClick: () => setSnackbar({message: 'Message updates from snackbar options should be in a snackbar'}),
          }), 
          children: 'Set Snackbar'
        },
      },
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      header: 'setSnackbar({})',
      params: [
        {name: 'message', description: 'Message to be displayed by the snackbar \nAll React elements are valid \nString is recommended',},
        {name: 'action', description: 'Optional. The content of the action button \nAll React elements are valid \nString is recommended',},
        {name: 'onClick', description: 'Method to be fired on clicking the action button',},
        {name: 'elevation', description: `Elevation of the snackbar relative to the containing element \nAllowed values - integers from 0 to 24. Defaults to 6`,},
      ],
    }
  },
  {
    type: 'SectionDivider',
  },
  {
    type: 'Subtitle', props: {display: 'Stylesheets'},
  },
  {
    type: 'Stylesheets', props: {stylesheets: [
      {
        name: 'SnackbarStyles',
        filename: 'Snackbar.css',
        data: SnackbarStyles,
        exportedAs: 'NA',
      },
    ]},
  },
];
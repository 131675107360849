import { combineReducers } from 'redux';

import listDux from './lib/listDux';
import culdDux from './lib/culdDux';

const { reducer: $getRates, actions: getRates, epics: getRatesEpics } = listDux({
  name: 'fxl.getRates',
  url: '/api/rates/v3/rateInventory/estimates/',
});

const { reducer: $shipments, actions: shipments, epics: shipmentsEpics } = culdDux({
  name: 'fxl.shipments',
  url: '/api/shipments/v2/',
});


export const actions = { getRates, shipments };
export const epics = [...getRatesEpics, ...shipmentsEpics];
export default combineReducers({ $getRates, $shipments });
import { combineReducers } from 'redux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';

const { reducer: $details, actions: details, epics: detailsEpics } = detailsDux({
  name: 'shippers',
  url: '/api/shippers',
});

const {reducer: $update, actions: update, epics: updateEpics} = updateDux({
  name: 'shippersUpdate',
  url: '/api/shippers',
});

export const actions = { details, update };
export const epics = [...detailsEpics, ...updateEpics];
export default combineReducers({ $details, $update });
import {all, one, not} from './operators';

export const routeStartsWith = (route) => (location) => location.pathname.startsWith(route);
export const routeEquals = (route) => (location) => location.pathname==route;
export const includes = ({equals=[], startsWith=[]}={}) => {
  if (typeof equals==='string')
    equals = [equals];
  if (typeof startsWith==='string')
    startsWith = [startsWith];
  return one(...[
    ...(equals || []).map(equals => routeEquals(equals)),
    ...(startsWith || []).map(startsWith => routeStartsWith(startsWith)),
  ]);
}
export const excludes = (...args) => not(includes(...args));
export const matchingRoutes = (routes=[]) => (location) => routes.filter(({equals, startsWith, exclude}) => all(...[
  includes({equals, startsWith}),
  excludes(exclude),
])(location)); 
export const closestMatchingRoute = (routes=[]) => (location) => {
  const {length=0, [length-1]: route={}} = matchingRoutes(routes)(location);
  return route;
};
export const checkRouteFlag = (flagName, routes, defaultValue = false) => location => {
  const {[flagName]: value = defaultValue} = (closestMatchingRoute(routes)(location) || {})
  return value
}
export const isProtected = (routes) => checkRouteFlag('isProtected', routes, true);
export const requiresFedexAccount = (routes) => checkRouteFlag('requiresFedexAccount', routes, false);
export const hideHeader = (routes) => checkRouteFlag('hideHeader', routes, false);
export const hideFooter = (routes) => checkRouteFlag('hideFooter', routes, false);
export const hideSideNav = (routes) => checkRouteFlag('hideSideNav', routes, false);
export const hideNotification = (routes) => checkRouteFlag('hideNotification', routes, false);

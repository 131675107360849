import Button from './Button';
import ButtonStyles from '!!raw-loader!./Button.css';

import Row from '../row';
import Icon from '../icon';
import Cell from '../cell';

export const documentation = [
  {
    type: 'Title', props: {display: 'Button'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Button Demo',
      preview: {
        type: Button,
        children: [
          {
            type: Row, 
            props: {alignItems: 'center'},
            children: [
              {type: Icon, props: {type: 'star', style: {marginRight: '8px'}}},
              {type: Cell, props: {children: 'Click Here'}},
            ],
          }
        ],
      },
      configurations: [
        {
          name: 'buttonPattern',
          label: 'Button Pattern',
          type: 'dropdown',
          options: ['text', 'outline', 'contained',],
          isValueArrayPlainString: true,
          isOptionsArrayPlainString: true,
        },
        {
          name: 'push',
          label: 'Push',
          type: 'dropdown',
          options: ['', 'top', 'bottom', 'right', 'left'],
          isOptionsArrayPlainString: true,
        },
      ],
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      params: [
        {name: 'children', description: 'Children of the DOM element \nAll React Components and DOM elements are valid',},
        {name: 'className', description: 'Class name to apply to the button element',},
        {name: 'style', description: 'Inline styles to apply to the button element',},
        {name: 'buttonPattern', description: `Type of button pattern. Allowed values - 'text', 'outline', 'contained' \nDefaults to 'contained'`,},
        {name: 'push', description: `Push button to one of the four directions - top, right, bottom, left - by 16px \nAllowed values are 'top', 'right', 'bottom', 'left'`,},
        {name: 'onClick', description: 'A method to be fired on button click',},
        {name: '-------', description: 'All the remaining parameters will be passed to underlying button element as is',},
        {name: '', description: '',},
      ],
    }
  },
  {
    type: 'SectionDivider',
  },
  {
    type: 'Subtitle', props: {display: 'Stylesheets'},
  },
  {
    type: 'Stylesheets', props: {stylesheets: [
      {
        name: 'ButtonStyles',
        filename: 'Button.css',
        data: ButtonStyles,
        exportedAs: `{ButtonStyles} from '/path/to/button'`,
      },
    ]},
  },
];
import { combineReducers } from 'redux';

import createDux from './lib/createDux';

const { reducer: $createFeedback, actions: createFeedback, epics: createFeedbackEpics } = createDux({
  name: 'feedback',
  url: '/api/webforms/feedback',
});

export const actions = { createFeedback };
export const epics = [...createFeedbackEpics];
export default combineReducers({ $createFeedback });
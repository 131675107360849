import {fromEvent, of, from, empty, combineLatest} from 'rxjs';
import {first, take, map, filter, mergeMap, switchMap, mapTo, withLatestFrom, catchError} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {ofType} from 'redux-observable';

const getAJAXOperator = ({
  withLatestState,
  request={},
  getUrl=(url) => url,
  getRequest = (url, { headers: { 'Content-Type': contentType = 'application/json', ...headers }={}, method='GET', ...request}, {0: {payload: {data, options}={}}={}, 1: {auth: {authToken}={}}={}}=[]) => ({
    ...request,
    url, 
    headers: {
      ...(contentType=='FormData' ? {} : { 'Content-Type': contentType }),
      ...(authToken ? {Authorization: `Bearer ${authToken}`} : {}),
      ...headers
    },
    method,
    ...((method != 'GET' && contentType != 'FormData') ? {body: {data, options}} : {body: data}),
  }), 
  processResponse=([{ response: { data = {}, error, success } = {} } = {}, {0: {payload={}}={}}=[]]=[]) =>  {
    return ({
      requestId: Math.random(),
      ...(!success ? {error: 'Server Error', errorMessage: 'Server Error'} : {}),
      ...(error ? {error, errorMessage: error.message || error.toString() || 'Server Error'} : {}),
      ...(data ? { ...payload, ...data} : {}),
    })
  }, 
  processError=(error) => ({error: error, errorMessage: error.toString() || 'Network Error'}),
  switchRequests=true,
}) => {
  const operator = switchRequests ? switchMap : mergeMap;
  return operator((data) => combineLatest([
    ajax(
      getRequest(
        getUrl(request.url, withLatestState ? data : [data]), 
        request, 
        withLatestState ? data : [data]
      )
    ).pipe(catchError((...args) => of(processError(...args)))),
    of(withLatestState ? data : [data])
  ]).pipe(
    map(processResponse), 
  ));
};

export const epic = (operators=[]) => (action$, state$) => action$.pipe(...operators.map(operator => {
  if (operator.ofType)
    return ofType(operator.ofType);
  if (operator=='first')
    return first();
  if (operator=='withLatestState')
    return withLatestFrom(state$);
  if (operator.isAJAX) {
    const {isAJAX, ...props} = operator;
    return getAJAXOperator(props)
  }
  if (operator.inActionType) {
    const {inActionType='inActionNotDefined'} = operator;
    return ofType(inActionType);
  }
  if (operator.outAction) {
    const {outAction=() => ({type: 'outActionNotDefined'}), getOutAction=({data, outAction}) => of(outAction(data))} = operator;
    return mergeMap(data => getOutAction({data, outAction}));
  }
  return operator;
}));

export const ajaxEpic = ({inActionType, outAction, getOutAction, withLatestState=false, request={}, ...ajaxProps}) => epic([
  {inActionType},
  ...(withLatestState ? ['withLatestState'] : []),
  ...(request.url ? [{isAJAX: true, withLatestState, request, ...ajaxProps}] : []),
  {outAction, getOutAction},
]);

export const setDetailsFromListEpic = ({idField='_id', setListActionType, setDetailsAction}) => epic([
  {inActionType: setListActionType},
  mergeMap(({payload: {items=[]}={}}={}) => from(items.map(({[idField]: _id, ...record}) => setDetailsAction({[idField]: _id, record})))),
]);

export const setDetailsFromUpdateEpic = ({idField='_id', setUpdateActionType, setDetailsAction}) => epic([
  {inActionType: setUpdateActionType},
  mergeMap(({payload: {error=undefined, errorMessage='', [idField]: _id, ...record}={}}={}) => of(setDetailsAction({[idField]: _id, record}))),
]);

export const getDetailsOnUpdateEpic = ({idField = '_id', setUpdateActionType, getDetailsAction}) => epic([
  {inActionType: setUpdateActionType},
  filter(({ payload: { record: { [idField]: _id}={}, error, errorMessage}}) => !error && !errorMessage && _id),
  mergeMap(({ payload: { record: { [idField]: _id}={}}={}}={}) => of(getDetailsAction(_id))),
]);

export const getDetailsOnCreateEpic = ({idField='_id', setCreateActionType, getDetailsAction}) => epic([
  {inActionType: setCreateActionType},
  filter(({payload: {[idField]: _id, error, errorMessage}}) => !error && !errorMessage && _id),
  mergeMap(({payload: {[idField]: _id}={}}={}) => of(getDetailsAction(_id))),
]);
import Cell from './Cell';

export const documentation = [
  {
    type: 'Title', props: {display: 'Cell'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Cell Demo',
      preview: {
        type: Cell,
        props: {children: 'Text', style: {backgroundColor: 'white', padding: '8px 16px',},},
        containerStyle: {display: 'flex', flexDirection:'row', width: '50%',},
      },
      configurations: [
        {
          name: 'flexGrow',
          label: 'Flex Grow',
          type: 'text',
        },
        {
          name: 'flexShrink',
          label: 'Flex Shrink',
          type: 'text',
        },
      ],
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      params: [
        {name: 'children', description: 'Children of the DOM element \nAll React Components and DOM elements are valid',},
        {name: 'className', description: 'Class name to apply to the element',},
        {name: 'style', description: 'Inline styles to apply to the element',},
        {name: 'ref', description: 'Reference to the underlying DOM element',},
        {name: 'flexGrow', description: `Flex 'grow' property along the main axis of the containing flex element`,},
        {name: 'flexShrink', description: `Flex 'shrink' property along the main axis of the containing flex element`,},
        {name: '-------', description: 'All the remaining parameters will be passed to underlying DOM element as is',},
        {name: '', description: '',},
      ],
    }
  },
];
import listDux from './lib/listDux';

const {reducer, actions, epics} = listDux({
  name: 'containerTypes',
  url: '/api/system/lookup/container-types-list',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200)
      return {items: response, count: response.length};
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

export {actions};
export {epics};
export default reducer;
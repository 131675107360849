import { ajaxEpic } from './epic';
import dux from './dux';

const createDux = ({
  name,
  initialState = { isCreating: false, data: undefined, error: undefined, errorMessage: '' },
  actions = [],
  epics = [],
  idField = '_id',
  createdRecordField = 'record',
  createPayload = (data) => ({ data, isCreating: true, isCreated: false }),
  setCreatePayload = ({ error = undefined, errorMessage = '', [idField]: _id, [createdRecordField]: record, ...payload } = {}) => ({
    ...payload,
    isCreating: false,
    error,
    errorMessage,
    data: undefined,
    record,
    [createdRecordField]: record,
    [idField]: _id,
    isCreated: !!_id || !!record,
  }),
  withLatestState = true,
  url,
  method = 'POST',
  headers = {},
  request = { url, method, headers },
  getUrl = (url) => url,
  getRequest,
  processResponse,
  processError,
  getOutAction,
  ...props
}) => dux({
  name: `${name}.$create`,
  initialState,
  actions: [
    { name: 'create', getPayload: createPayload },
    { name: 'set', getPayload: setCreatePayload },
    ...actions,
  ],
  epics: [
    ({ getActionType, getAction }) => ajaxEpic({ inActionType: getActionType('create'), outAction: getAction('set'), withLatestState, request, getUrl, getRequest, processResponse, processError, getOutAction, }),
    ...epics,
  ],
  ...props,
});

export default createDux;
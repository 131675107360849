import * as utils from './utils';

const {all, one, not, isLoggedIn, isLoggedOut, isProtected, hideHeader, hideSideNav, hideFooter, hideNotification, hasLinkedFedexAccount, requiresFedexAccount, isInternalUser} = utils;

const getNonRoutingApps =  routes => ({
  login: {
    activeWhen: all(isProtected(routes), isLoggedOut),
  },
  linkFedexAccount: {
    activeWhen: all(requiresFedexAccount(routes), not(isInternalUser), not(hasLinkedFedexAccount), isLoggedIn),
  },
  sideNav: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideSideNav(routes))),
  },
  header: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideHeader(routes))),
  },
  footer: {
    activeWhen: all(one(not(isProtected(routes)), isLoggedIn), not(hideFooter(routes))),
  },
  notification: {
    activeWhen: all(isProtected(routes), isLoggedIn, not(hideNotification(routes))),
  },
  snackbar: {
    activeWhen: one(()=>(true)),
  }
});

export default getNonRoutingApps;
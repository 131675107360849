import React, {useState, useEffect} from 'react';

import DefaultBreakPoints from './DefaultBreakPoints';
import MediaQueryContext from './MediaQueryContext';
import { MediaQueryProvider as MediaQueryProviderV1 } from '../media-query';

const useMediaQuery = (queries) => {
  const [matches, setMatches] = useState({});
  const updateMatches = (key, value) => setMatches((matches={}) => {
    const updatedMatches = {...matches, [key]: value};
    const {lteXXS=false, lteXS=false, lteSM=false, lteMD=false, lteLG=false} = updatedMatches;
    const lteXL = true;
    const xxs = lteXXS;
    const xs = !lteXXS && lteXS;
    const sm = !lteXS && lteSM;
    const md = !lteSM && lteMD;
    const lg = !lteMD && lteLG;
    const xl = !lteLG;
    const ltXXS = lteXXS && !xxs;
    const ltXS = lteXS && !xs;
    const ltSM = lteSM && !sm;
    const ltMD = lteMD && !md;
    const ltLG = lteLG && !lg;
    const ltXL = lteXL && !xl;
    const gteXXS = !ltXXS;
    const gteXS = !ltXS;
    const gteSM = !ltSM;
    const gteMD = !ltMD;
    const gteLG = !ltLG;
    const gteXL = !ltXL;
    const gtXXS = !lteXXS;
    const gtXS = !lteXS;
    const gtSM = !lteSM;
    const gtMD = !lteMD;
    const gtLG = !lteLG;
    const gtXL = !lteXL;

    return {...updatedMatches, xxs, xs, sm , md, lg, xl, ltXXS, ltXS, ltSM, ltMD, ltLG, ltXL, lteXXS, lteXS, lteSM, lteMD, lteLG, lteXL, gteXXS, gteXS, gteSM, gteMD, gteLG, gteXL, gtXXS, gtXS, gtSM, gtMD, gtLG, gtXL,};
  });

  useEffect(() => {
    const mediaQueries = Object.entries(queries).map(([key, query]) => {
      const mediaQuery = window.matchMedia(query);
      const mediaQueryListener = $event => updateMatches(key, $event.matches);
      mediaQuery.addListener(mediaQueryListener);
      mediaQueryListener(mediaQuery);
      return [mediaQuery, mediaQueryListener];
    });
    return () => mediaQueries.map(([mediaQuery, mediaQueryListener]) => mediaQuery.removeListener(mediaQueryListener));
  },[]);
   
  return matches;
}

export default function MediaQueryProvider({breakpoints={}, mediaQueries={}, children}) {
  const {xxs=DefaultBreakPoints.xxs, xs=DefaultBreakPoints.xs, sm=DefaultBreakPoints.sm, md=DefaultBreakPoints.md, lg=DefaultBreakPoints.lg} = breakpoints;
  mediaQueries = {
    lteXXS: `(max-width:  ${xxs}px)`,
    lteXS: `(max-width:  ${xs}px)`,
    lteSM: `(max-width:  ${sm}px)`,
    lteMD: `(max-width: ${md}px)`,
    lteLG: `(max-width: ${lg}px)`,
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    fullHeader: '(min-width: 680px)',
    fullWidthTable: '(max-width: 768px)',
    ...mediaQueries,
  };
  const mediaQueryMatches = useMediaQuery(mediaQueries); 
  return (
    <MediaQueryContext.Provider value={{mediaQuery: mediaQueryMatches}}>
      <MediaQueryProviderV1>
        {children}
      </MediaQueryProviderV1>
    </MediaQueryContext.Provider>
  );
}
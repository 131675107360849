import {combineReducers} from 'redux';

import listDux from './lib/listDux';


const {reducer: $fetchRateList, actions: fetchRateList, epics: fetchRateListEpics} = listDux({
  name: 'fetchRateList',
  url: '/api/rates/v3/rateInventory/estimates/',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200) {
      if(response.success){
        return {
          ...response.data,
          isFetching: false,
          success: true
        };
      }
      else {
        return {...response}
      }
    }
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

export const actions = {fetchRateList,};
export const epics = [ ...fetchRateListEpics];
export default combineReducers({$fetchRateList,});

import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import culdDux from './lib/culdDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'users',
  url: '/api/users/',
});

const { reducer: $internalUsersList, actions: internalUsersList, epics: internalUsersListEpics } = listDux({
  name: 'internalUsers',
  url: '/api/users',
});

const { reducer: $roles, actions: roles, epics: rolesEpics } = culdDux({
  name: 'roles',
  url: '/api/auth/v2/roles/',
});

const { reducer: $accesses, actions: accesses, epics: accessesEpics } = culdDux({
  name: 'accesses',
  url: '/api/auth/v2/accesses/',
});

export const actions = { list, internalUsersList, roles, accesses };
export const epics = [...listEpics, ...internalUsersListEpics, ...rolesEpics, ...accessesEpics];
export default combineReducers({ $list, $internalUsersList, $roles, $accesses });
import Elevation from '!!raw-loader!./Elevation.css';

import Surface from './Surface';

import Cell from '../cell';

export const documentation = [
  {
    type: 'Title', props: {display: 'Surface'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Surface Demo',
      preview: {
        type: Surface,
        props: {
          style: {backgroundColor: 'white', width: '200px', padding: '16px'},
          elevation: 4,
          hoverElevation: 8,
        },
        children: [
          {type: Cell, props: {children: 'First'}},
          {type: Cell, props: {children: 'Second Element'}},
          {type: Cell, props: {children: 'Third'}},
        ],
        containerStyle: {backgroundColor: 'white'},
      },
      configurations: [
        {
          name: 'elevation',
          label: 'Elevation',
          type: 'text',
          value: 4,
        },
        {
          name: 'hoverElevation',
          label: 'Hover Elevation',
          type: 'text',
          value: 8,
        },
      ],
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      params: [
        {name: 'children', description: 'Children of the DOM element \nAll React Components and DOM elements are valid',},
        {name: 'className', description: 'Class name to apply to the element',},
        {name: 'style', description: 'Inline styles to apply to the element',},
        {name: 'ref', description: 'Reference to the underlying DOM element',},
        {name: 'elevation', description: `Elevation of the surface relative to the containing element \nAllowed values - integers from 0 to 24. Defaults to 0`,},
        {name: 'hoverElevation', description: `Elevation of the surface relative to the containing element when it is hovered \nAllowed values - integers from 0 to 24. Defaults to 'elevation'`,},
        {name: '', description: '',},
      ],
    }
  },
  {
    type: 'SectionDivider',
  },
  {
    type: 'Subtitle', props: {display: 'Stylesheets'},
  },
  {
    type: 'Stylesheets', props: {stylesheets: [
      {
        name: 'Elevation',
        filename: 'Elevation.css',
        data: Elevation,
        exportedAs: 'NA',
      },
    ]},
  },
];
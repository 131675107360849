import {triggerAppChange} from 'single-spa';
import store from '../../store.js';
import {getAuthToken, getUsers} from '../../dux/auth';

export const unsubscribe = store.subscribe(() => {
  triggerAppChange();
});
store.dispatch(getAuthToken());
store.dispatch(getUsers());

export const getAuthContext = () => {
  const {auth: {isAuthenticating=true, isLoggedIn=false, authToken, user, enterprisePlan: {data: enterprisePlan}={}}={}} = store.getState();
  const {hasLinkedFedexAccount, effectiveAccesses: accesses={}} = (user || {});
  return {isAuthenticating, isLoggedIn, authToken, accesses, enterprisePlan, hasLinkedFedexAccount, user};
}
export const refreshUser = () => {
  store.dispatch(getAuthToken());
}
export const persistCurrentAuthToken = () => {
  const {authToken} = getAuthContext();
  localStorage.setItem('auth.persistedAuthToken', authToken);
}
export const isLoggedIn = () => {
  const {isAuthenticating, isLoggedIn} = getAuthContext();
  return isLoggedIn && !isAuthenticating;
}
export const isLoggedOut = () => {
  const {isAuthenticating, isLoggedIn} = getAuthContext();
  return !isLoggedIn && !isAuthenticating;
}
export const hasLinkedFedexAccount = () => {
  const {hasLinkedFedexAccount} = getAuthContext()
  return !!hasLinkedFedexAccount
}
export const hasAccess = (access, accessLevel) => () => {
  const {accesses} = getAuthContext();
  const hasAccess = accesses && accesses[access] && accesses[access].levels && accesses[access].levels.length>0 && accesses[access].levels.indexOf('Never')<0 && (!accessLevel || accesses[access].levels.indexOf('Always')>-1 || accesses[access].levels.indexOf(accessLevel)>-1);
  return !!hasAccess;
}
export const hasAccesses = (accesses=[]) => () => {
  return accesses.map(access => hasAccess(access)()).reduce((final, curr) => final && curr, true);
}
export const hasEnterprisePlan = (requiredPlan) => () => {
  const {enterprisePlan={}} = getAuthContext(); 
  const hasEnterprisePlan = (enterprisePlan && enterprisePlan[requiredPlan] && enterprisePlan[requiredPlan].isActive) || (!enterprisePlan.isActive && !requiredPlan);
  return !!hasEnterprisePlan;
}
export const hasEnterprisePlans = (requiredPlans=[]) => () => {
  return requiredPlans.map(requiredPlan => hasEnterprisePlan(requiredPlan)()).reduce((final, curr) => final && curr, true);
}

export const isInternalUser = () => {
  const { user: { userCompanyType }={} } = getAuthContext();
  return userCompanyType == 'Internal';
}
import listDux from './lib/listDux';
import {combineReducers} from 'redux';

const {reducer: $ports, actions: ports, epics: portsEpics} = listDux({
  name: 'ports',
  url: '/api/system/lookup/ports-list/',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200)
      return {items: response, count: response.length};
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

const {reducer: $portsByUnloc, actions: portsByUnloc, epics: portsByUnlocEpics} = listDux({
  name: 'portsByUnloc',
  url: '/api/system/lookup/ports-by-unloc/',
  processResponse: ([{response=[], status}]=[]) => {
    if (status==200)
      return {items: response.data.data.reduce((final, el) => ({...final, [el.unloc]: el}), {}), count: response.data.data.length};
    return {error: 'Server Error', errorMessage: 'Server Error'};
  }
});

export const actions = {ports, portsByUnloc};
export const epics = [...portsEpics, ...portsByUnlocEpics];
export default combineReducers({$ports, $portsByUnloc});


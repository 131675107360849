const dux = ({
  name,
  initialState={},
  getActionType=(type) => `${name}.${type}`,
  isOwnAction=(actionType='') => actionType.startsWith(name),
  actions=[],
  epics=[],
  actionReducers={},
  getActionReducer = (actionType, actionReducers={}) => actionReducers[actionType.split('.').slice(-1)[0]],
  reducer=({initialState, isOwnAction}) => (state=initialState, {type, payload={}}={}) => ({
    ...state,
    ...(isOwnAction(type) ? (getActionReducer(type, actionReducers) ? getActionReducer(type, actionReducers)(payload, state) : payload) : {}),
  }),
  getAction=(actions) => (name) => actions[name],
}) => {
  actions = actions.reduce((final, {name, type=name, getPayload=(...args) => ({...args})}) => ({
    ...final,
    [name]: (...args) => ({
      type: getActionType(...[type, ...args]),
      payload: getPayload(...args),
    })
  }), {});
  epics = epics.map(epic => epic({
    getActionType,
    getAction: getAction(actions),
  }));
  return {actions, epics, reducer: reducer({initialState, isOwnAction}),};
};

export default dux;

import React, {useContext} from 'react';

import MediaQueryContext from './MediaQueryContext';

export default function useMediaQueryProps(props={}) {
  const {mediaQuery={}} = useContext(MediaQueryContext) || {};
  
  const mediaQueryRegex = /^(?<mediaQueryPropName>\w+)\$\$(?<mediaQueryKey>\w+)$/;

  const propsArray = Object.entries(props).map(([propName, value]) => {
    const match = propName.match(mediaQueryRegex);
    if (!match)
      return [propName, value, false, false];
    const { groups: { mediaQueryKey, mediaQueryPropName } = {} } = match;
    if (!mediaQueryKey || !mediaQuery[mediaQueryKey])
      return [propName, value, true, false];
    return [mediaQueryPropName, value, true, true];
  });

  const nonMediaQueryProps = propsArray.filter(({[2]: isMediaQueryProp = false}=[]) => !isMediaQueryProp).reduce((final = {}, [propName, value]) => ({ ...final, [propName]: value }), {});

  const mediaQueryProps = propsArray.filter(({[2]: isMediaQueryProp = false, [3]: isIncluded = false}=[]) => isMediaQueryProp && isIncluded).reduce((final={}, [propName, value]) => {
    const {[propName]: currentValue=props.propName, style} = final;
    if (propName=='style')
      return {...final, [propName]: {...currentValue, ...value}};
    if (propName=='className')
      return { ...final, [propName]: `${currentValue} ${value}`};
    if (propName=='width' || propName=='order' || propName=='display')
      return {...final, style: {...style, [propName]: value}};
    if (propName=='props')
      return {...final, ...value};
    return {...final, [propName]: value};
  }, nonMediaQueryProps);

  return { mediaQuery, ...mediaQueryProps };
}
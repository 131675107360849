import React from 'react';
import populateMediaClasses from './populateMediaClasses';

import * as Styles from './Styles.css';

export const MediaQueryContext = React.createContext();
export {default as populateMediaClasses} from './populateMediaClasses';

const mediaQueries = {
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)',
  fullHeader: '(min-width: 680px)',
  fullWidthTable: '(max-width: 768px)',

  xs: '(max-width:  599px)',
  sm: '(min-width:  600px) and (max-width: 959px)',
  md: '(min-width:  960px) and (max-width: 1279px)',
  lg: '(min-width: 1280px) and (max-width: 15999px)',

  'gt-xs': '(min-width:  600px)',
  'gt-sm': '(min-width:  960px)',
  'gt-md': '(min-width: 1280px)',
  'gt-lg': '(min-width: 16000px)',
};

export function mediaQuery(Component) {
 
  const {Consumer} = MediaQueryContext;
  const  MediaQueryComponent = (props) => {
    const {
      forwardedRef,

      className='',
      classNameXs='',
      classNameGtXs='',
      classNameSm='',
      classNameGtSm='',
      classNameMd='',
      classNameGtMd='',
      classNameLg='',
      classNameGtLg='',

      widthXs='',
      widthGtXs='',
      widthSm='',
      widthGtSm='',
      widthMd='',
      widthGtMd='',
      widthLg='',
      widthGtLg='',

      flexOrderXs='',
      flexOrderGtXs='',
      flexOrderSm='',
      flexOrderGtSm='',
      flexOrderMd='',
      flexOrderGtMd='',
      flexOrderLg='',
      flexOrderGtLg='',

      layoutXs='',
      layoutGtXs='',
      layoutSm='',
      layoutGtSm='',
      layoutMd='',
      layoutGtMd='',
      layoutLg='',
      layoutGtLg='',

      layoutAlignXs='',
      layoutAlignGtXs='',
      layoutAlignSm='',
      layoutAlignGtSm='',
      layoutAlignMd='',
      layoutAlignGtMd='',
      layoutAlignLg='',
      layoutAlignGtLg='',

      ...forwardedProps
    } = props;

    const camelize = (string) => {
      if (!string)
        return string;
      const camelizedString =  string.split('-').map(word=> `${word.charAt(0).toUpperCase()}${word.slice(1)}`).join('');
      return `${camelizedString.charAt(0).toLowerCase()}${camelizedString.slice(1)}`;
    }

    const widthClass = (width) => {
      return Styles[`width${width}`];
    };

    const layoutClass = (direction) => {
      return Styles[camelize(`layout-${direction}`)];
    };

    const layoutAlignClass = (layoutAlign) => {
      if (!layoutAlign)
        return '';

      let [justify='start', align='stretch'] = layoutAlign.split(' ');
      justify = Styles[camelize(`layout-justify-${justify}`)];
      align = Styles[camelize(`layout-align-${align}`)];

      const className = justify ? (align ? `${justify} ${align}`: justify): align;
      return className;
    };

    const flexOrderClass = (order) => {
      if (!order)
        return '';

      const flexOrderLowerLimit = -8;
      const flexOrderUpperLimit =  8;

      if (order < flexOrderLowerLimit || order > flexOrderUpperLimit) {
        console.warn(
          'given, flexOrder =', order,
          `. supported values are (${flexOrderLowerLimit} <= order <= ${flexOrderUpperLimit})`
        );
      }

      return Styles[camelize(`flex-order-${order}`)];
    };

    const getClasses = (bp) => ([
      props[`className${bp}`],
      layoutClass(props[`layout${bp}`]),
      layoutAlignClass(props[`layoutAlign${bp}`]),
      widthClass(props[`width${bp}`]),
      flexOrderClass(props[`flexOrder${bp}`]),
    ]);

    const mediaClassesArray = [
      ['xs', getClasses('Xs')],
      ['sm', getClasses('Sm')],
      ['md', getClasses('Md')],
      ['lg', getClasses('Lg')],
      ['gt-xs', getClasses('GtXs')],
      ['gt-sm', getClasses('GtSm')],
      ['gt-md', getClasses('GtMd')],
      ['gt-lg', getClasses('GtLg')],
    ].map(([breakpoint, classNames]) => {
      const className = classNames.filter(className => className && className.trim()).join(' ');
      return className && [breakpoint, className]
    }).filter(v => v);

    const mediaClasses = Object.fromEntries(mediaClassesArray);
    
    if (mediaClassesArray.length>0)
      console.warn('Use of media-query v1 is now deprecated. Please migrate to v2', Component, props);

    return (
      <Consumer>
        {({
          mediaQuery={},
        }={}) => {
          const mediaQueriedClassName = populateMediaClasses({
            className,
            mediaQuery,
            mediaClasses,
          });
          return <Component
                   {...forwardedProps}
                   mediaQuery={mediaQuery}
                   className={mediaQueriedClassName}
                   ref={forwardedRef} />;
        }}
      </Consumer>
    );
  };

  return React.forwardRef((props, ref) => {
    return <MediaQueryComponent {...props} forwardedRef={ref} />
  });
}

export class MediaQueryProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.mediaQueries = this.createListeners(mediaQueries);
  }
  componentWillUnmount() {
    this.mediaQueries = this.mediaQueries.map(({mediaQuery, listener}) => {
      mediaQuery.removeListener(listener);
      return mediaQuery;
    });
  }
  createListeners(mediaQueries) {
    return Object.entries(mediaQueries).map(([key, query]) => {      
      let mediaQuery = window.matchMedia(query);
      let listener = ($event) => {this.setState({[key]: $event.matches})};
      mediaQuery.addListener(listener);
      listener(mediaQuery);
      return {mediaQuery, listener};
    });
  }
  render() {
    const {Provider} = MediaQueryContext;
    const {children} = this.props;
    return (
      <Provider value={{mediaQuery: this.state}}>
        {children}
      </Provider>
    )
  }
}

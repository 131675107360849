import Row from './Row';

import Cell from '../cell';

export const documentation = [
  {
    type: 'Title', props: {display: 'Row'}
  },
  {
    type: 'ComponentDemo', 
    props: {
      title: 'Row Demo',
      preview: {
        type: Row,
        props: {style: {backgroundColor: 'white', height: '200px',}, alignItems: 'center', justifyContent: 'center'},
        children: [
          {type: Cell, props: {children: 'First', style: {backgroundColor: 'red',},},},
          {type: Cell, props: {children: 'Second Element', style: {backgroundColor: 'blue',},},},
          {type: Cell, props: {children: 'Third', style: {backgroundColor: 'green',},},},
        ],
        containerStyle: {display: 'flex', flexDirection:'column', justifyContent: 'center', height: '100%', width: '100%'},
      },
      configurations: [
        {
          name: 'alignItems',
          label: 'Align Items',
          type: 'dropdown',
          options: ['', 'normal', 'stretch', 'center', 'start', 'end', 'flex-start', 'flex-end', 'baseline', 'first baseline', 'last baseline;', 'safe center', 'unsafe center', 'inherit', 'initial', 'unset',],
          value: 'center',
          isValueArrayPlainString: true,
          isOptionsArrayPlainString: true,
        },
        {
          name: 'justifyContent',
          label: 'Justify Content',
          type: 'dropdown',
          options: ['', 'center', 'start', 'end', 'flex-start', 'flex-end', 'left', 'right', 'normal', 'space-between', 'space-around', 'space-evenly', 'stretch', 'safe center', 'unsafe center', 'inherit', 'initial', 'unset',],
          value: 'center',
          isValueArrayPlainString: true,
          isOptionsArrayPlainString: true,
        },
        {
          name: 'flexGrow',
          label: 'Flex Grow',
          type: 'text',
        },
        {
          name: 'flexShrink',
          label: 'Flex Shrink',
          type: 'text',
        },
      ],
    },
  },
  {
    type: 'SectionDivider'
  },
  {
    type: 'Subtitle', props: {display: 'Input Parameters'}
  },
  {
    type: 'ParamsTable',
    props: {
      params: [
        {name: 'children', description: 'Children of the DOM element \nAll React Components and DOM elements are valid',},
        {name: 'className', description: 'Class name to apply to the element',},
        {name: 'style', description: 'Inline styles to apply to the element',},
        {name: 'ref', description: 'Reference to the underlying DOM element',},
        {name: 'alignItems', description: `Flex 'align-items' property to align children along the main axis`,},
        {name: 'justifyContent', description: `Flex 'justify-content' property to align children along the secondary axis`,},
        {name: 'flexGrow', description: `Flex 'grow' property along the main axis of the containing flex element`,},
        {name: 'flexShrink', description: `Flex 'shrink' property along the main axis of the containing flex element`,},
        {name: '-------', description: 'All the remaining parameters will be passed to underlying DOM element as is',},
        {name: '', description: '',},
      ],
    }
  },
];
import listDux from './lib/listDux';

const {reducer, actions, epics} = listDux({
  name: 'exchangeRates',
  url: '/api/exchange-rates',
  setListPayload: ({base, rates={}, supportedCurrencies=[], error=null, errorMessage=''}={}) => ({...(base ? {[base]: rates} : {}), supportedCurrencies, error, errorMessage, isFetching: false,}),
});

export {actions};
export {epics};
export default reducer;
import { combineReducers } from 'redux';

import listDux from './listDux';
import updateDux from './updateDux';
import createDux from './createDux';
import detailsDux from './detailsDux';

const culdDux = ({
  createInitialState, updateInitialState, listInitialState, detailsInitialState, 
  createActions, updateActions, listActions, detailsActions,
  createEpics, updateEpics, listEpics, detailsEpics,
  createReducer, updateReducer, listReducer, detailsReducer,
  url, createUrl = url, updateUrl = url, listUrl = url, detailsUrl = url,
  createMethod, updateMethod, listMethod, detailsMethod,
  createHeaders, updateHeaders, listHeaders, detailsHeaders,
  createRequest, updateRequest, listRequest, detailsRequest,
  createGetUrl, updateGetUrl, listGetUrl, detailsGetUrl,
  createGetRequest, updateGetRequest, listGetRequest, detailsGetRequest,
  createProcessResponse, updateProcessResponse, listProcessResponse, detailsProcessResponse,
  createProcessError, updateProcessError, listProcessError, detailsProcessError,
  createGetOutAction, updateGetOutAction, listGetOutAction, detailsGetOutAction,
  ...props
}) => {
  const createDuxProps = { ...props, initialState: createInitialState, actions: createActions, epics: createEpics, reducer: createReducer, url: createUrl, method: createMethod, headers: createHeaders, request: createRequest, getUrl: createGetUrl, getRequest: createGetRequest, processResponse: createProcessResponse, processError: createProcessError, getOutAction: createGetOutAction, };
  const updateDuxProps = { ...props, initialState: updateInitialState, actions: updateActions, epics: updateEpics, reducer: updateReducer, url: updateUrl, method: updateMethod, headers: updateHeaders, request: updateRequest, getUrl: updateGetUrl, getRequest: updateGetRequest, processResponse: updateProcessResponse, processError: updateProcessError, getOutAction: updateGetOutAction, };
  const listDuxProps = { ...props, initialState: listInitialState, actions: listActions, epics: listEpics, reducer: listReducer, url: listUrl, method: listMethod, headers: listHeaders, request: listRequest, getUrl: listGetUrl, getRequest: listGetRequest, processResponse: listProcessResponse, processError: listProcessError, getOutAction: listGetOutAction, };
  const detailsDuxProps = {...props, initialState: detailsInitialState, actions: detailsActions, epics: detailsEpics, reducer: detailsReducer, url: detailsUrl, method: detailsMethod, headers: detailsHeaders, request: detailsRequest, getUrl: detailsGetUrl, getRequest: detailsGetRequest, processResponse: detailsProcessResponse, processError: detailsProcessError, getOutAction: detailsGetOutAction,};

  const { reducer: $create, actions: create, epics: $createEpics } = createDux(createDuxProps);
  const { reducer: $update, actions: update, epics: $updateEpics } = updateDux(updateDuxProps);
  const { reducer: $list, actions: list, epics: $listEpics } = listDux(listDuxProps);
  const { reducer: $details, actions: details, epics: $detailsEpics } = detailsDux(detailsDuxProps);

  return {
    reducer: combineReducers({$create, $update, $list, $details}),
    actions: {create, update, list, details},
    epics: [...$createEpics, ...$updateEpics, ...$listEpics, ...$detailsEpics],
  }
};

export default culdDux;
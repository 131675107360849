export function mediaQueryClassNameFilter({mediaQuery={}, mediaClasses={}}) {
  if (JSON.stringify(mediaQuery) == '{}'
      || JSON.stringify(mediaClasses) == '{}')
    return '';

  const breakpoints = Object.entries(mediaQuery).filter(([_, v]) => v);
  const filteredClasses = breakpoints.map(([breakpoint]) => (
    mediaClasses[breakpoint] || ''
  )).join(' ');
  
  return filteredClasses;
}

export default function populateMediaClasses({mediaQuery, mediaClasses, className=''}) {
  const mediaClass = mediaQueryClassNameFilter({mediaQuery, mediaClasses});
  return `${className} ${mediaClass}`;
}

import React from 'react';

import useMediaQueryProps from './useMediaQueryProps';

export default function withMediaQueryProps(Component) {
  const MediaQueryComponent = ({forwardedRef, ...props}) => {
    const forwardedProps = useMediaQueryProps(props);
    return <Component ref={forwardedRef} {...forwardedProps} />;
  }
  return React.forwardRef((props, ref) => {
    return <MediaQueryComponent {...props} forwardedRef={ref} />;
  });
}
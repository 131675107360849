import Common from '!!raw-loader!./Common.css';
import Colours from '!!raw-loader!./Colours.css';
import Fonts from '!!raw-loader!./Fonts.css';
import Scrollbar from '!!raw-loader!./Scrollbar.css';

export const documentation = [
  {
    type: 'Title', props: {display: 'Theme'}
  },
  {
    type: 'Subtitle', props: {display: 'Stylesheets'},
  },
  {
    type: 'Stylesheets', props: {stylesheets: [
      {
        name: 'Common',
        filename: 'Common.css',
        data: Common,
        exportedAs: `{Common} from '/path/to/theme'`,
      },
      {
        name: 'Colours',
        filename: 'Colours.css',
        data: Colours,
        exportedAs: `{Colours} from '/path/to/theme'`,
      },
      {
        name: 'Fonts',
        filename: 'Fonts.css',
        data: Fonts,
        exportedAs: `{Fonts} from '/path/to/theme'`,
      },
      {
        name: 'Scrollbar',
        filename: 'Scrollbar.css',
        data: Scrollbar,
        exportedAs: `{Scrollbar} from '/path/to/theme'`,
      },
    ]},
  },
];
import React from 'react';

import Cell from '../cell';
import {Elevation} from '../theme';

class Surface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    }
  }
  getElevationClass({elevation=0, hoverElevation=elevation}) {
    const {[`elevation${elevation}Dp`]: elevationClass=''} = Elevation;
    const {[`elevation${hoverElevation}Dp`]: hoverElevationClass=''} = Elevation;
    return {elevationClass,hoverElevationClass};
  }
  onMouseEnter() {
    const {hoverElevation} = this.props;
    this.setState({isHovered: true});
  }
  onMouseLeave() {
    const {elevation} = this.props;
    this.setState({isHovered: false});
  }
  render() {
    const {innerRef, className='', elevation, hoverElevation, onClick, ...props} = this.props;
    const {isHovered} = this.state;
    const {elevationClass, hoverElevationClass} = this.getElevationClass({elevation, hoverElevation})
    return (
      <Cell ref={innerRef} {...{
        className: `${isHovered ? hoverElevationClass : elevationClass} ${className}`,
        ...(hoverElevation ? {
          onMouseEnter: () => this.onMouseEnter(),
          onMouseLeave: () => this.onMouseLeave(),
        } : {}),
        onClick,
        ...props,
      }} />
    )
  }
}

export default React.forwardRef((props, ref) => <Surface innerRef={ref} {...props} />);